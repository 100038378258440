// src/components/CustomNavbar.js

import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';

const Header = styled.header`
  width: 100%;
  height: auto;
  background-color: #fff;

  .navbar {
    border-bottom: 1px solid #ddd;
    font-size: 1.125rem;
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    width: auto;
    background-color: #fff;
  }

  .navbar-brand {
    color: #000000;

    .bi {
      width: 40px;
      height: 32px;
    }
  }

  .nav-pills {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .nav-link.active {
    background-color: #f8f9fa;
    color: #007bff;
    font-weight: bold;
  }

  /* Tablets (600px a 768px) */
  @media (min-width: 600px) and (max-width: 768px) {
    .navbar {
      flex-direction: row;
      padding-top: 0px;
      width: auto;
      background-color: #fff;
    }
  }

  @media (max-width: 768px) {
    .navbar {
      flex-direction: row;
      padding-top: 0px;
      width: 100%;
      background-color: #fff;
    }

    .navbar-brand {
      margin-right: auto;
    }

    .nav-pills {
      justify-content: flex-start;
      flex-grow: 1;
    }

    .navbar-collapse {
      flex-basis: 100%;
      flex-grow: 1;
      align-items: center;
      width: 100%;
    }

    .nav-link {
      color: #667;
      border-bottom: 1px solid;
      border-bottom-color: rgba(0, 0, 0, 0.28);
      border-radius: inherit;
      width: 100%;
      padding-left: 20px;
    }
  }
`;

const CustomNavbar = () => {
  const [navbarExpanded, setNavbarExpanded] = useState(false);

  const handleToggle = () => {
    setNavbarExpanded(!navbarExpanded);
  };

  const handleNavItemClick = () => {
    if (navbarExpanded) {
      setNavbarExpanded(false);
    }
  };

  return (
    <Header>
      <Navbar expand="lg" className="navbar" expanded={navbarExpanded}>
        <Navbar.Brand href="/" className="navbar-brand">
          <svg className="bi me-2" width="40" height="32"><use xlinkHref="#bootstrap"></use></svg>
          <span className="fs-4">CRLV na Hora</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
        <Navbar.Collapse id="basic-navbar-nav" className="navbar-collapse">
          <Nav className="nav">
            <NavDropdown title="Consultas" id="services-dropdown">
            {/*<NavDropdown.Item href="/consultaestadual" onClick={handleNavItemClick}>Base Estadual</NavDropdown.Item>*/}
             {/* <NavDropdown.Item href="/consultabinnacional" onClick={handleNavItemClick}>Base Nacional</NavDropdown.Item>*/}
              <NavDropdown.Item href="/consultapremium" onClick={handleNavItemClick}>Consulta Premium</NavDropdown.Item>
              <NavDropdown.Item href="/consultagravame" onClick={handleNavItemClick}>Gravame</NavDropdown.Item>
              <NavDropdown.Item href="/renainf" onClick={handleNavItemClick}>Renainf</NavDropdown.Item>
              <NavDropdown.Item href="/sinistro" onClick={handleNavItemClick}>Sinistro</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Documentos Digitais" id="digital-doc-dropdown">
              <NavDropdown.Item href="/atpve" onClick={handleNavItemClick}>2ª Via ATPVE</NavDropdown.Item>
              <NavDropdown.Item href="/documentosdigitais" onClick={handleNavItemClick}>CRLV Digital</NavDropdown.Item>
              <NavDropdown.Item href="/crvdigital" onClick={handleNavItemClick}>CRV Digital</NavDropdown.Item>
            </NavDropdown>
            
            <NavDropdown title="Políticas" id="policies-dropdown">
              <NavDropdown.Item href="/lgpd" onClick={handleNavItemClick}>LGPD</NavDropdown.Item>
              <NavDropdown.Item href="/termosUso" onClick={handleNavItemClick}>Termos de Uso</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Usuário" id="usuario-dropdown">
            <Nav.Link href="/cadastro" className="nav-link" onClick={handleNavItemClick}>Criar Conta</Nav.Link>
            <Nav.Link href="/pagamentos" className="nav-link" onClick={handleNavItemClick}>Recarga Pix</Nav.Link>
            <Nav.Link href="/reembolso" className="nav-link" onClick={handleNavItemClick}>Solicitar reembolso</Nav.Link>
            <Nav.Link href="/servicos" className="nav-link" onClick={handleNavItemClick}>Serviços</Nav.Link>
            <Nav.Link href="/tutorial" className="nav-link" onClick={handleNavItemClick}>Tutorial</Nav.Link>
            </NavDropdown>
            <NavDropdown title="Contato" id="usuario-dropdown">
            <Nav.Link href="https://api.whatsapp.com/send?phone=5511999184789&text=Ol%C3%A1,%20preciso%20falar%20com%20suporte." className="nav-link" onClick={handleNavItemClick}>WhatsApp</Nav.Link>
            
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Header>
  );
};

export default CustomNavbar;
